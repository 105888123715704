'use client';

import React from 'react';
import { useServerInsertedHTML } from 'next/navigation';
import { StyleProvider, createCache, extractStyle } from '@ant-design/cssinjs';
export default function AntdStyledComponentsRegistry({
  children
}: {
  children: React.ReactNode;
}) {
  const [cache] = React.useState(() => createCache());
  useServerInsertedHTML(() => <style id="antd" dangerouslySetInnerHTML={{
    __html: extractStyle(cache, true)
  }}></style>);
  return <StyleProvider cache={cache} data-sentry-element="StyleProvider" data-sentry-component="AntdStyledComponentsRegistry" data-sentry-source-file="AntdStyledComponentsRegistry.tsx">{children}</StyleProvider>;
}