'use client';

import React, { useEffect } from 'react';
import * as CookieConsent from '@/utils/CookieManager';
import { CookieConsentConfig } from '..';
const pluginConfig: CookieConsentConfig = {
  disablePageInteraction: true,
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom left',
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: 'box',
      position: 'left',
      equalWeightButtons: true,
      flipButtons: false
    }
  },
  onFirstConsent: function () {
    console.log('onFirstAction fired');
  },
  onConsent: function ({
    cookie
  }) {
    console.log('onConsent fired ...');
  },
  onChange: function ({
    changedCategories,
    cookie
  }) {
    console.log('onChange fired ...');
  },
  categories: {
    necessary: {
      readOnly: true,
      enabled: true
    },
    analytics: {
      autoClear: {
        cookies: [{
          name: /^(_ga|_gid)/
        }]
      }
    }
  },
  language: {
    default: 'pt',
    translations: {
      pt: {
        consentModal: {
          title: 'Bem-vindo(a) à Apoio Genealogico!',
          description: 'Utilizamos cookies para aprimorar a sua experiência em nosso site e compreender como você interage com nossos conteúdos. O uso de cookies de rastreamento só será ativado mediante seu consentimento.',
          acceptAllBtn: 'Aceitar todos',
          acceptNecessaryBtn: 'Recusar todos',
          showPreferencesBtn: 'Gerenciar preferências',
          footer: ``
        },
        preferencesModal: {
          title: 'Preferências de Cookies',
          acceptAllBtn: 'Aceitar todos',
          acceptNecessaryBtn: 'Recusar todos',
          savePreferencesBtn: 'Salvar preferências',
          closeIconLabel: 'Fechar',
          sections: [{
            title: 'Utilização de Cookies',
            description: 'Este site utiliza cookies para garantir as funcionalidades essenciais e melhorar sua experiência online. Você pode personalizar suas preferências para cada categoria. Para mais informações sobre o uso de cookies, consulte nossa <a href="/privacy" class="cc__link">Política de Privacidade</a>.'
          }, {
            title: 'Cookies Estritamente Necessários',
            description: 'Esses cookies são essenciais para o funcionamento adequado do site e não podem ser desativados em nossos sistemas.',
            linkedCategory: 'necessary'
          }, {
            title: 'Cookies de Desempenho e Análise',
            linkedCategory: 'analytics',
            cookieTable: {
              headers: {
                name: 'Nome',
                domain: 'Serviço',
                description: 'Descrição',
                expiration: 'Validade'
              },
              body: [{
                name: '_ga',
                domain: 'Google Analytics',
                description: 'Cookie utilizado pelo <a href="#das">Google Analytics</a> para análise de dados de navegação.',
                expiration: 'Expira em 12 dias'
              }, {
                name: '_gid',
                domain: 'Google Analytics',
                description: 'Cookie utilizado pelo <a href="#das">Google Analytics</a> para identificação de usuários.',
                expiration: 'Sessão'
              }]
            }
          }, {
            title: 'Mais Informações',
            description: 'Para dúvidas relacionadas à nossa política de cookies e suas escolhas, por favor, entre em contato pelo e-mail <a class="cc__link" href="mailto:contato@solbra.com.br">contato@solbra.com.br</a>.'
          }]
        }
      },
      en: {
        consentModal: {
          title: 'Welcome to Apoio Genealogico!',
          description: 'We use cookies to enhance your experience on our site and understand how you interact with our content. Tracking cookies will only be activated with your consent.',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage preferences',
          footer: ``
        },
        preferencesModal: {
          title: 'Cookie Preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Save preferences',
          closeIconLabel: 'Close',
          sections: [{
            title: 'Use of Cookies',
            description: 'This site uses cookies to ensure essential functionalities and enhance your online experience. You can customize your preferences for each category. For more information on cookie usage, please see our <a href="/privacy" class="cc__link">Privacy Policy</a>.'
          }, {
            title: 'Strictly Necessary Cookies',
            description: 'These cookies are essential for the proper functioning of the site and cannot be disabled in our systems.',
            linkedCategory: 'necessary'
          }, {
            title: 'Performance and Analytics Cookies',
            linkedCategory: 'analytics',
            cookieTable: {
              headers: {
                name: 'Name',
                domain: 'Service',
                description: 'Description',
                expiration: 'Expiration'
              },
              body: [{
                name: '_ga',
                domain: 'Google Analytics',
                description: 'Cookie used by <a href="#das">Google Analytics</a> for navigation data analysis.',
                expiration: 'Expires in 12 days'
              }, {
                name: '_gid',
                domain: 'Google Analytics',
                description: 'Cookie used by <a href="#das">Google Analytics</a> for user identification.',
                expiration: 'Session'
              }]
            }
          }, {
            title: 'More Information',
            description: 'For questions related to our cookie policy and your choices, please contact us at <a class="cc__link" href="mailto:contato@solbra.com.br">contato@solbra.com.br</a>.'
          }]
        }
      }
    }
  }
};
export const CookiePopup = () => {
  useEffect(() => {
    CookieConsent.run(pluginConfig);
  }, []);
  return <></>;
};